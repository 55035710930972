import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import axios from "axios";
import { BASE_URL, EMPLOYER_TOKEN } from "../../utils/Constants";
import toast from "react-hot-toast";

import LogoutAction from "./LogoutAction";
import AttendanceType from "../type/attendanceType";

interface Action {
  type: string;
  payload?: any;
}

const AttendanceAction = {
  getAttendanceEmployees:
    (): ThunkAction<void, RootState, null, Action> => async (dispatch) => {
      const token: string | any = localStorage.getItem(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return;
      }

      try {
        const result: any = await axios.get(
          BASE_URL + "employee-list-for-attendance",
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
            },
          },
        );

        if (result.data.status) {
          dispatch({
            type: AttendanceType.GET_ATTENDANCE_EMPLOYEES,
            payload: result.data.data,
          });
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },
};

export default AttendanceAction;
