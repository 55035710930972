import { Route, Routes } from "react-router-dom";
import Home from "../views/dashboard/Home";
import Login from "../views/auth/Login";
import Profile from "../views/employer/Profile";
import Error from "../views/Error";
import EmployeeList from "../views/employee/EmployeeList";
import EmployeeProfile from "../views/employee/EmployeeProfile";
import CreateEmployee from "../views/employee/CreateEmployee";
import Department from "../views/department/Department";
import Designation from "../views/designation/Designation";
import Attendance from "../views/attendance/Attendance";

function PageRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/dashboard" element={<Home />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/create-employee" element={<CreateEmployee />} />
      <Route path="/employee-list" element={<EmployeeList />} />
      <Route path="/employee-profile/:id" element={<EmployeeProfile />} />
      <Route path="/department" element={<Department />} />
      <Route path="/designation" element={<Designation />} />
      <Route path="/mark-attendance" element={<Attendance />} />
      <Route path="*" element={<Error />} />
    </Routes>
  );
}

export default PageRoutes;
