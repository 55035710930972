import WebLayout from "../../layout/WebLayout";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import { useForm } from "react-hook-form";
import DesignationAction from "../../stores/action/designationAction";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import AttendanceAction from "../../stores/action/attendanceAction";
import moment from "moment";
import { today } from "../../utils/Constants";

type FormData = {
  name: string;
};

function Attendance() {
  const [search, set_search] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [attendanceData, setAttendanceData] = useState<{ [key: string]: any }>(
    {},
  );

  const dispatch = useDispatch<any>();

  const { attendanceEmployeeList } = useSelector(
    (state: RootState) => state.attendance,
  );

  const methods = useForm<FormData>({
    defaultValues: {
      name: "",
    },
  });

  const {
    register,
    reset,
    formState: { errors },
  } = methods;

  const getAttendance = useCallback(async () => {
    await dispatch(AttendanceAction.getAttendanceEmployees());
  }, [dispatch]);

  useEffect(() => {
    getAttendance();
  }, [getAttendance]);

  const handleStatusToggle = (id: string) => {
    setAttendanceData((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        status: prev[id]?.status === "A" ? "P" : "A", // Toggle status between "A" and "P"
      },
    }));
  };

  return (
    <WebLayout pageName={"Attendance"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <div className="col-12">
                <ul className="breadcrumb breadcrumb-style mb-0">
                  <li className="breadcrumb-item">
                    <h4 className="page-title">Mark Attendance</h4>
                  </li>
                  <li className="breadcrumb-item bcrumb-1">
                    <Link to="/dashboard">
                      <i className="fas fa-home"></i> Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">Mark Attendance</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            {/* Attendance List */}
            <div className="col-lg-12 col-md-12 col-sm-8">
              <div className="card">
                <div className="header">
                  <h2>
                    Mark Attendance on {moment(today).format("DD-MMM-YYYY")}
                  </h2>
                </div>

                <div className="body">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th className={"ubuntu-medium"}>Employee Id</th>
                          <th className={"ubuntu-medium"}>Name</th>
                          <th className={"ubuntu-medium"}>Status</th>
                          <th className={"ubuntu-medium"}>Type Of Leave</th>
                          <th className={"ubuntu-medium"}>Reason</th>
                        </tr>
                      </thead>
                      <tbody>
                        {attendanceEmployeeList?.length > 0 ? (
                          attendanceEmployeeList.map(
                            (dataList: any, index: number) => (
                              <tr key={dataList.id}>
                                <td>{dataList.employeeId}</td>
                                <td>{dataList.name}</td>
                                <td>
                                  <div className="form-check form-switch">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id={`status-switch-${dataList.id}`}
                                      checked={
                                        attendanceData[dataList.id]?.status ===
                                        "A"
                                      }
                                      onChange={() =>
                                        handleStatusToggle(dataList.id)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`status-switch-${dataList.id}`}
                                    >
                                      {attendanceData[dataList.id]?.status ===
                                      "A"
                                        ? "A"
                                        : "P"}
                                    </label>
                                  </div>
                                </td>
                                <td>
                                  {attendanceData[dataList.id]?.status ===
                                    "A" && (
                                    <select className={"form-control"}>
                                      <option value="" disabled selected>
                                        Select Type Of Leave
                                      </option>
                                      {["Casual", "Sick", "Paid"]?.map(
                                        (val: any, index: number) => (
                                          <option key={index} value={val}>
                                            {val}
                                          </option>
                                        ),
                                      )}
                                    </select>
                                  )}
                                </td>
                                <td>
                                  {attendanceData[dataList.id]?.status ===
                                    "A" && (
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Reason"
                                    />
                                  )}
                                </td>
                              </tr>
                            ),
                          )
                        ) : (
                          <tr>
                            <td colSpan={3} className="text-center">
                              No matching records found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default Attendance;
