import AttendanceType from "../type/attendanceType";

export interface ReducerAction {
  type: string;
  payload?: any;
}

const INITIAL_STATE = {
  attendanceEmployeeList: [],
};

const AttendanceReducers = (state = INITIAL_STATE, action: ReducerAction) => {
  switch (action.type) {
    case AttendanceType.GET_ATTENDANCE_EMPLOYEES:
      return {
        ...state,
        attendanceEmployeeList: action.payload,
      };

    default:
      return state;
  }
};

export default AttendanceReducers;
