import React, { useEffect, useCallback, useState } from "react";
import WebLayout from "../../layout/WebLayout";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import WebAction from "../../stores/action/webAction";
import { useForm, FormProvider } from "react-hook-form";
import { Link } from "react-router-dom";
import { today } from "../../utils/Constants";

type FormData = {
  image: FileList;
  name: string;
  fatherName: string;
  dateOfBirth: string;
  phone: string;
  state: number;
  district: number;
  pinCode: string;
  correspondenceAddress: string;
  permanentAddress: string;
  email: string;
  password: string;
  employeeID: string;
  department: string;
  designation: number;
  dateOfJoining: string;
  joiningSalary: string;
  accountHolderName: string;
  accountNumber: string;
  bankName: string;
  ifscCode: string;
  branch: string;
};

function CreateEmployee() {
  const dispatch = useDispatch<any>();
  const {
    stateList,
    districtList,
    employeeDepartmentList,
    employeeDesignationList,
  } = useSelector((state: RootState) => state.web);

  const methods = useForm<FormData>({
    defaultValues: {},
    shouldUnregister: true, // Set to true to unregister fields when unmounted
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    trigger,
  } = methods;

  const [btnLoading, setBtnLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const getStates = useCallback(async () => {
    await dispatch(WebAction.getState());
  }, [dispatch]);

  const getDepartments = useCallback(async () => {
    await dispatch(WebAction.getDepartment());
    await dispatch(WebAction.getDesignation());
  }, [dispatch]);

  useEffect(() => {
    getStates();
    getDepartments();
  }, [getStates, getDepartments]);

  const getDistrict = useCallback(
    async (state_id: number) => {
      try {
        await dispatch(WebAction.getDistrict({ state_id }));
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch],
  );

  const handleStateChange = async (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const stateId = parseInt(event.target.value, 10);
    setValue("state", stateId);
    await getDistrict(stateId);
  };

  const getStepFields = (step: number): Array<keyof FormData> => {
    switch (step) {
      case 1:
        return [
          "image",
          "name",
          "fatherName",
          "dateOfBirth",
          "phone",
          "state",
          "district",
          "pinCode",
          "correspondenceAddress",
          "permanentAddress",
        ];
      case 2:
        return ["email", "password"];
      case 3:
        return [
          "employeeID",
          "department",
          "designation",
          "dateOfJoining",
          "joiningSalary",
        ];
      case 4:
        return [
          "accountHolderName",
          "accountNumber",
          "bankName",
          "ifscCode",
          "branch",
        ];
      default:
        return [];
    }
  };

  const onSubmit = async (data: FormData) => {
    setBtnLoading(true);
    try {
      // Dispatch your submit action here
      // Example:
      // await dispatch(WebAction.createEmployee(data));
      console.log("Form Submitted Successfully:", data);
      // Reset the form or navigate as needed
      reset();
      setCurrentStep(1);
    } catch (error) {
      console.error("Submission Error:", error);
      // Handle submission errors here
    } finally {
      setBtnLoading(false);
    }
  };

  const nextStep = async () => {
    const stepFields: Array<keyof FormData> = getStepFields(currentStep);
    const isValid = await trigger(stepFields);
    if (isValid) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const prevStep = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <div className="row clearfix">
              <div className="col-lg-3 col-md-12">
                <div className="form-group">
                  <label>Image</label>
                  <input
                    type="file"
                    className="form-control"
                    accept="image/*"
                    {...register("image", {
                      validate: {
                        acceptedFormats: (files) => {
                          const file = files?.[0];
                          const acceptedFormats = [
                            "image/jpeg",
                            "image/png",
                            "image/gif",
                          ];
                          if (file && !acceptedFormats.includes(file.type)) {
                            return "Only JPEG, PNG, or GIF files are allowed";
                          }
                          return true;
                        },
                        fileSize: (files) => {
                          const file = files?.[0];
                          if (file && file.size > 2 * 1024 * 1024) {
                            return "Image size should not exceed 2MB";
                          }
                          return true;
                        },
                      },
                    })}
                  />
                  {errors.image && (
                    <span className="text-danger">{errors.image.message}</span>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="form-group">
                  <label>
                    Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    {...register("name", { required: "Name is required" })}
                  />
                  {errors.name && (
                    <span className="text-danger">{errors.name.message}</span>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="form-group">
                  <label>
                    Father's Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Father's Name"
                    {...register("fatherName", {
                      required: "Father's Name is required",
                    })}
                  />
                  {errors.fatherName && (
                    <span className="text-danger">
                      {errors.fatherName.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="form-group">
                  <label>
                    Date Of Birth <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    {...register("dateOfBirth", {
                      required: "Date of Birth is required",
                    })}
                    max={`${today}`}
                  />
                  {errors.dateOfBirth && (
                    <span className="text-danger">
                      {errors.dateOfBirth.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="form-group">
                  <label>
                    Phone <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Phone"
                    {...register("phone", {
                      required: "Phone is required",
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: "Phone must be 10 digits",
                      },
                    })}
                    maxLength={10}
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors.phone && (
                    <span className="text-danger">{errors.phone.message}</span>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="form-group">
                  <label>
                    State <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    {...register("state", { required: "State is required" })}
                    onChange={handleStateChange}
                  >
                    <option value="" disabled selected>
                      Select State
                    </option>
                    {stateList?.map((val: any, index: number) => (
                      <option key={index} value={val.id}>
                        {val.name}
                      </option>
                    ))}
                  </select>
                  {errors.state && (
                    <span className="text-danger">{errors.state.message}</span>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="form-group">
                  <label>
                    District <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    {...register("district", {
                      required: "District is required",
                    })}
                  >
                    <option value="" disabled selected>
                      Select District
                    </option>
                    {districtList?.map((val: any, index: number) => (
                      <option key={index} value={val.id}>
                        {val.name}
                      </option>
                    ))}
                  </select>
                  {errors.district && (
                    <span className="text-danger">
                      {errors.district.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="form-group">
                  <label>
                    Pincode <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Pincode"
                    {...register("pinCode", {
                      required: "Pincode is required",
                      pattern: {
                        value: /^[0-9]{6}$/,
                        message: "Pincode must be 6 digits",
                      },
                    })}
                    maxLength={6}
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors.pinCode && (
                    <span className="text-danger">
                      {errors.pinCode.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    Correspondence Address{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <textarea
                    rows={4}
                    className="form-control no-resize"
                    placeholder="Correspondence Address"
                    {...register("correspondenceAddress", {
                      required: "Correspondence Address is required",
                    })}
                  ></textarea>
                  {errors.correspondenceAddress && (
                    <span className="text-danger">
                      {errors.correspondenceAddress.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    Permanent Address <span className="text-danger">*</span>
                  </label>
                  <textarea
                    rows={4}
                    className="form-control no-resize"
                    placeholder="Permanent Address"
                    {...register("permanentAddress", {
                      required: "Permanent Address is required",
                    })}
                  ></textarea>
                  {errors.permanentAddress && (
                    <span className="text-danger">
                      {errors.permanentAddress.message}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="row clearfix">
              <div className="col-lg-3 col-md-12">
                <div className="form-group">
                  <label>
                    Email <span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="E-mail"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: "Invalid email address",
                      },
                    })}
                  />
                  {errors.email && (
                    <span className="text-danger">{errors.email.message}</span>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="form-group" style={{ position: "relative" }}>
                  <label>
                    Password <span className="text-danger">*</span>
                  </label>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Password"
                    {...register("password", {
                      required: "Password is required",
                      minLength: {
                        value: 6,
                        message: "Password must be at least 6 characters",
                      },
                    })}
                  />
                  {errors.password && (
                    <span className="text-danger">
                      {errors.password.message}
                    </span>
                  )}
                  <span
                    onClick={togglePasswordVisibility}
                    style={{
                      position: "absolute",
                      right: "10px",
                      bottom: "10%",
                      cursor: "pointer",
                      color: "gray",
                    }}
                  >
                    {showPassword ? (
                      <i className="fa fa-eye-slash"></i>
                    ) : (
                      <i className="fa fa-eye"></i>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div className="row clearfix">
              <div className="col-lg-3 col-md-12">
                <div className="form-group">
                  <label>
                    Employee ID <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Employee ID"
                    {...register("employeeID", {
                      required: "Employee ID is required",
                    })}
                  />
                  {errors.employeeID && (
                    <span className="text-danger">
                      {errors.employeeID.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="form-group">
                  <label>
                    Department <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    {...register("department", {
                      required: "Department is required",
                    })}
                  >
                    <option value="" disabled selected>
                      Select Department
                    </option>
                    {employeeDepartmentList?.map((val: any, index: number) => (
                      <option key={index} value={val.id}>
                        {val.name}
                      </option>
                    ))}
                  </select>
                  {errors.department && (
                    <span className="text-danger">
                      {errors.department.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="form-group">
                  <label>
                    Designation <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    {...register("designation", {
                      required: "Attendance is required",
                    })}
                  >
                    <option value="" disabled selected>
                      Select Designation
                    </option>
                    {employeeDesignationList?.map((val: any, index: number) => (
                      <option key={index} value={val.id}>
                        {val.name}
                      </option>
                    ))}
                  </select>
                  {errors.designation && (
                    <span className="text-danger">
                      {errors.designation.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="form-group">
                  <label>
                    Date Of Joining <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    {...register("dateOfJoining", {
                      required: "Date of Joining is required",
                    })}
                    max={`${today}`}
                  />
                  {errors.dateOfJoining && (
                    <span className="text-danger">
                      {errors.dateOfJoining.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="form-group">
                  <label>
                    Joining Salary <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Joining Salary"
                    {...register("joiningSalary", {
                      required: "Joining Salary is required",
                      min: {
                        value: 0,
                        message: "Joining Salary must be a positive number",
                      },
                    })}
                  />
                  {errors.joiningSalary && (
                    <span className="text-danger">
                      {errors.joiningSalary.message}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </>
        );
      case 4:
        return (
          <>
            <div className="row clearfix">
              <div className="col-lg-3 col-md-12">
                <div className="form-group">
                  <label>
                    Account Holder Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Account Holder Name"
                    {...register("accountHolderName", {
                      required: "Account Holder Name is required",
                    })}
                  />
                  {errors.accountHolderName && (
                    <span className="text-danger">
                      {errors.accountHolderName.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="form-group">
                  <label>
                    Account Number <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Account Number"
                    {...register("accountNumber", {
                      required: "Account Number is required",
                      pattern: {
                        value: /^[0-9]{9,18}$/,
                        message:
                          "Account Number must be between 9 to 18 digits",
                      },
                    })}
                    maxLength={18}
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors.accountNumber && (
                    <span className="text-danger">
                      {errors.accountNumber.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="form-group">
                  <label>
                    Bank Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Bank Name"
                    {...register("bankName", {
                      required: "Bank Name is required",
                    })}
                  />
                  {errors.bankName && (
                    <span className="text-danger">
                      {errors.bankName.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="form-group">
                  <label>
                    IFSC Code <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="IFSC Code"
                    {...register("ifscCode", {
                      required: "IFSC Code is required",
                      pattern: {
                        value: /^[A-Z]{4}0[A-Z0-9]{6}$/,
                        message: "Invalid IFSC Code",
                      },
                    })}
                    maxLength={11}
                    onKeyPress={(e) => {
                      const allowed = /[A-Z0-9]/.test(e.key);
                      if (!allowed) {
                        e.preventDefault();
                      }
                    }}
                    style={{ textTransform: "uppercase" }}
                  />
                  {errors.ifscCode && (
                    <span className="text-danger">
                      {errors.ifscCode.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="form-group">
                  <label>
                    Branch <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Branch"
                    {...register("branch", {
                      required: "Branch is required",
                    })}
                  />
                  {errors.branch && (
                    <span className="text-danger">{errors.branch.message}</span>
                  )}
                </div>
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <WebLayout pageName="CreateEmployee">
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <div className="col-12">
                <ul className="breadcrumb breadcrumb-style">
                  <li className="breadcrumb-item">
                    <h4 className="page-title">
                      Create ASL Account (Employee)
                    </h4>
                  </li>
                  <li className="breadcrumb-item bcrumb-1">
                    <Link to="/dashboard">
                      <i className="fas fa-home"></i> Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Create ASL Account (Employee)
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row clearfix">
            <div className="col-lg-12 col-md-12">
              <div className="card">
                <div className="profile-tab-box">
                  <ul className="nav">
                    {[1, 2, 3, 4].map((step) => (
                      <li
                        key={step}
                        className={`nav-item tab-all ${
                          currentStep >= step ? "active-step" : ""
                        }`}
                        style={{ width: "25%", textAlign: "center" }}
                      >
                        <button
                          type="button"
                          className="nav-link"
                          disabled={currentStep < step}
                          onClick={() => setCurrentStep(step)}
                        >
                          {`${step}. ${
                            step === 1
                              ? "PERSONAL DETAILS"
                              : step === 2
                                ? "ACCOUNT LOGIN"
                                : step === 3
                                  ? "COMPANY DETAILS"
                                  : "BANK ACCOUNT DETAILS"
                          }`}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-12">
              <div className="card project_widget">
                <div className="header">
                  <h2>
                    {currentStep === 1 && "PERSONAL DETAILS"}
                    {currentStep === 2 && "ACCOUNT LOGIN"}
                    {currentStep === 3 && "COMPANY DETAILS"}
                    {currentStep === 4 && "BANK ACCOUNT DETAILS"}
                  </h2>
                </div>
                <div className="body">
                  <FormProvider {...methods}>
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      encType="multipart/form-data"
                    >
                      {renderStep()}
                      <div className="col-md-12 d-flex justify-content-between mt-3">
                        {currentStep > 1 && (
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={prevStep}
                            disabled={btnLoading}
                          >
                            Previous
                          </button>
                        )}
                        {currentStep < 4 && (
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={nextStep}
                            disabled={btnLoading}
                          >
                            Next
                          </button>
                        )}
                        {currentStep === 4 && (
                          <button
                            type="submit"
                            className="btn btn-success"
                            disabled={btnLoading}
                          >
                            Submit
                          </button>
                        )}
                      </div>
                    </form>
                  </FormProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default CreateEmployee;
